// Main javascript entry point
// Should handle bootstrapping/starting application


(function($){
$(document).ready(function(){
  $('.js-clone-child').on('click',function(e){
    e.preventDefault(); e.stopPropagation();
      var count = $('.children .child').length+1;
      var $child = $('.child').last();
      $elem = $child.clone();
      $elem.find('.count').text(count);
      $child.parent().append($elem);
    return false;
  });
});
}(jQuery));
